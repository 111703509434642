export const Categories = [
  {
    slug: "smileys-emotion",
    name: "Uśmiechy",
    subCategories: [
      "face-smiling",
      "face-affection",
      "face-tongue",
      "face-hand",
      "face-neutral-skeptical",
      "face-sleepy",
      "face-unwell",
      "face-hat",
      "face-glasses",
      "face-concerned",
      "face-negative",
      "face-costume",
      "cat-face",
      "monkey-face",
      "emotion",
    ],
  },
  {
    slug: "people-body",
    name: "Ludzie",
    subCategories: [
      "hand-fingers-open",
      "hand-fingers-partial",
      "hand-single-finger",
      "hand-fingers-closed",
      "hands",
      "hand-prop",
      "body-parts",
      "person",
      "person-gesture",
      "person-role",
      "person-fantasy",
      "person-activity",
      "person-sport",
      "person-resting",
      "family",
      "person-symbol",
    ],
  },
  {
    slug: "animals-nature",
    name: "Zwierzęta i natura",
    subCategories: [
      "animal-mammal",
      "animal-bird",
      "animal-amphibian",
      "animal-reptile",
      "animal-marine",
      "animal-bug",
      "plant-flower",
      "plant-other",
    ],
  },
  {
    slug: "food-drink",
    name: "Jedzenie i picie",
    subCategories: [
      "food-fruit",
      "food-vegetable",
      "food-prepared",
      "food-asian",
      "food-marine",
      "food-sweet",
      "drink",
      "dishware",
    ],
  },
  {
    slug: "travel-places",
    name: "Podróże",
    subCategories: [
      "place-map",
      "place-geographic",
      "place-building",
      "place-religious",
      "place-other",
      "transport-ground",
      "transport-water",
      "transport-air",
      "hotel",
      "time",
      "sky-weather",
    ],
  },
  {
    slug: "activities",
    name: "Aktywność",
    subCategories: ["event", "award-medal", "sport", "game", "arts-crafts"],
  },
  {
    slug: "objects",
    name: "Obiekty",
    subCategories: [
      "clothing",
      "sound",
      "music",
      "musical-instrument",
      "phone",
      "computer",
      "light-video",
      "book-paper",
      "money",
      "mail",
      "writing",
      "office",
      "lock",
      "tool",
      "science",
      "medical",
      "household",
      "other-object",
    ],
  },
  {
    slug: "symbols",
    name: "Symbole",
    subCategories: [
      "transport-sign",
      "warning",
      "arrow",
      "religion",
      "zodiac",
      "av-symbol",
      "gender",
      "math",
      "punctuation",
      "currency",
      "other-symbol",
      "keycap",
      "alphanum",
      "geometric",
    ],
  },
  {
    slug: "flags",
    name: "Flagi",
    subCategories: ["flag", "country-flag", "subdivision-flag"],
  },
];

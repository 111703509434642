import React, { useContext } from "react";

import SingleEmoji from "./SingleEmoji";
import { AuthContext } from "../context/AuthContext";
import CopyMessage from "./CopyMessage";

export default function EmojiList({ isLoading, emojiList }) {
  const { isCopyMess } = useContext(AuthContext);
  return (
    <>
      {isLoading && <div className="loader"></div>}
      {emojiList[0] == "empty" && (
        <p className="emoji-list__empty">Nie znaleziono emoji</p>
      )}
      {emojiList && emojiList.length > 0 && (
        <div className="emoji-list__items">
          {emojiList.map((item, key) => (
            <SingleEmoji key={key} item={item} />
          ))}
        </div>
      )}

      {isCopyMess && <CopyMessage />}
    </>
  );
}

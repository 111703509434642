import React, { useEffect, useState, useContext } from "react";

import { AuthContext } from "../context/AuthContext";
import EmojiList from "./EmojiList";

export default function CategoryEmoji() {
  const { activeCategory } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [emojiList, setEmojiList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const url = `https://emoji-api.com/categories/${activeCategory}?access_key=${process.env.REACT_APP_EMOJI_API}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setEmojiList(data);
        setIsLoading(false);
      });
  }, [activeCategory]);
  return (
    <div className="emoji-list">
      <EmojiList isLoading={isLoading} emojiList={emojiList} />
    </div>
  );
}

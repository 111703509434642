import React, { useContext } from "react";
import CategoryList from "./CategoryList";

import { AuthContext } from "../context/AuthContext";
import SearchEmoji from "./SearchEmoji";
import CategoryEmoji from "./CategoryEmoji";

export default function Home() {
  const { activeCategory } = useContext(AuthContext);
  return (
    <div className="main">
      <CategoryList />
      {activeCategory === "search" ? <SearchEmoji /> : <CategoryEmoji />}
    </div>
  );
}

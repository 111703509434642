import React, { useState, useEffect } from "react";
import EmojiList from "./EmojiList";

export default function SearchEmoji() {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emojiList, setEmojiList] = useState([]);

  const handleOnChange = (event) => {
    setSearchTerm(event.target.value);
    setEmojiList([]);
  };

  useEffect(() => {
    if (searchTerm) {
      const url = `https://emoji-api.com/emojis?search=${searchTerm}&access_key=${process.env.REACT_APP_EMOJI_API}`;
      const timeoutId = setTimeout(() => {
        setIsLoading(true);
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            setEmojiList(data);
            setIsLoading(false);
            if (!data) {
              setEmojiList(["empty"]);
            }
          });
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [searchTerm]);
  return (
    <div className="emoji-list">
      <h1 className="emoji-list__title">Wyszukaj emoji</h1>
      <input
        className="emoji-list__input"
        type="text"
        onChange={handleOnChange}
        value={searchTerm}
      />
      {searchTerm && <EmojiList isLoading={isLoading} emojiList={emojiList} />}
    </div>
  );
}

import React, { useContext } from "react";

import { AuthContext } from "../context/AuthContext";

export default function SingleEmoji({ item }) {
  const { setIsCopyMess } = useContext(AuthContext);

  const copyEmoji = (item) => {
    setIsCopyMess(false);
    navigator.clipboard.writeText(item);
    setIsCopyMess(true);
    setTimeout(() => {
      const copyMessage = document.querySelector(".copy-message");
      copyMessage.classList.add("copy-message--fade-out");
    }, 2000);
    setTimeout(() => {
      setIsCopyMess(false);
    }, 2300);
  };
  return (
    <span
      className="emoji-list__item"
      onClick={() => {
        copyEmoji(item.character);
      }}
    >
      {item.character}
    </span>
  );
}

import React, { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [activeCategory, setActiveCategory] = useState("smileys-emotion");
  const [isCopyMess, setIsCopyMess] = useState(false);

  return (
    <AuthContext.Provider
      value={{ activeCategory, setActiveCategory, isCopyMess, setIsCopyMess }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

import { Categories } from "./data/Categories";

export default function CategoryList() {
  const { activeCategory, setActiveCategory } = useContext(AuthContext);
  const categories = Categories;

  const categoryNameHandler = (clickedCategory) => {
    setActiveCategory(clickedCategory);
  };

  return (
    <div className={`category-list`}>
      <div className="category-list__items">
        <div
          className={`category-list__item ${
            activeCategory === "search" ? "category-list__item--active" : ""
          }`}
        >
          <button
            className="category-list__button"
            onClick={(e) => {
              categoryNameHandler("search");
            }}
          >
            Wyszukiwarka
          </button>
        </div>
        {categories.map((category, index) => (
          <div
            className={`category-list__item ${
              activeCategory === category.slug
                ? "category-list__item--active"
                : ""
            }`}
            key={index}
          >
            <button
              className="category-list__button"
              data-slug={category.slug}
              onClick={(e) => {
                categoryNameHandler(e.target.dataset.slug);
              }}
            >
              {category.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
